/* eslint-disable fp/no-mutation */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import i18next from 'i18next'
import {setBatteryEVChargeDetails} from 'lib/services/batteryDetailsAPI'
import {HTTPS_API_STATUS, HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {batteryProfileSelector, setBpDevicesValues} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {ACTION_TYPE, DIALOG_TYPE, TOAST_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {isUserHasAccess, isDemoUserBlocked} from 'lib/utils/userDetails'
import {showBatteryForEvCharging} from 'lib/utils/siteDetails'
import Analytics from 'lib/utils/analytics'
import {isWeb} from '../../../../utils/utility'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import {showToast} from '../../atoms/Toast'
import {ToggleSwitch, toggleStatusInfo} from '../../atoms/ToggleSwitch'
import {updateBatterySliceValues} from '../../organisms/BatteryDetails/bdCore'
import {isStormAlertActive} from '../../organisms/StormGuardDetails/sgCore'
import {getDevices} from '../../atoms/EvTopUpSchedule/evScheduleCore'
import {getCardDetails, getCbfevToggleStatus, getDescription, getInfoDescription} from './cbfevCore'
import {setCbfevValue} from './cbfevSlice'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Use Battery For EV
 * *********************** *********************** *********************** */

export const ChargeBatteryForEv = () => {
  const {loading, toggleStatus} = useSelector(state => state.components.chargeBatteryForEv)
  const batteryProfile = useSelector(batteryProfileSelector)
  const {details} = batteryProfile
  const cbfevTitle = i18next.t('bp259')
  const cbfevDescription = getDescription(toggleStatus)
  const cbfevDetails = getCardDetails(details)
  const cbfevButtonLabel = cbfevDetails.buttonLabel
  const cbfevButtonActionType = cbfevDetails.buttonActionType

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setLoading = val => dispatch(setCbfevValue({loading: val}))
  const setInProgress = val => dispatch(setCbfevValue({inProgress: val}))
  const setDeviceData = obj => dispatch(setBpDevicesValues(obj))
  const setToggleStatus = val => dispatch(setCbfevValue({toggleStatus: val}))
  const setRequestType = val => dispatch(setCbfevValue({requestType: val}))

  // useEffect on mount
  useEffect(() => {
    updateBatterySliceValues({source: 'cbfev', dispatch, data: details})
  }, [batteryProfile.loading])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const getStatusInfoDetails = () => {
    const cbfevInfoDescription = getInfoDescription()
    const obj = {
      className: 'cbfev__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: cbfevTitle,
      content: cbfevInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  // On Click Info Icon
  const onClickInfo = () => {
    const obj = getStatusInfoDetails()
    showDialog(obj)
  }

  // Handle Toggle Change
  const handleToggleChange = value => {
    if (isDemoUserBlocked()) return

    if (isStormAlertActive(details)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp244'),
        autoClose: 3000,
      })
      return
    }
    setToggleStatus(value)
  }

  const onActionButtonClick = obj => {
    // // Check User Access
    if (!isUserHasAccess()) {
      return
    }

    if (isStormAlertActive(details)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp244'),
        autoClose: 3000,
      })
      return
    }

    if (obj.type === ACTION_TYPE.SAVE) {
      save()
    }
  }

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  // isInfoChanged
  function isInfoChanged() {
    const chargeBatteryForEv = getCbfevToggleStatus(details)
    let isValid = false

    if (loading) {
      return true
    }

    if (toggleStatus !== chargeBatteryForEv) {
      isValid = true
    } else {
      isValid = false
    }

    return isValid
  }

  // Save
  async function save() {
    let devices = details?.devices?.iqEvse
    if (details?.devices?.thirdPartyEvse?.length) {
      devices = getDevices(details)
    }
    if (!_.isBoolean(devices?.useBatteryFrSelfConsumption)) {
      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp261'),
      })
      return
    }

    toggleStatus === TOGGLE_SWITCH_STATUS.ON
      ? Analytics.sendClickEvent('IQEVSE_SC_Use_Battery_On', 'IQ_EVSE_Profile')
      : Analytics.sendClickEvent('IQEVSE_SC_Use_Battery_Off', 'IQ_EVSE_Profile')

    const obj = {
      useBatteryFrSelfConsumption: toggleStatus === TOGGLE_SWITCH_STATUS.ON,
    }

    setRequestType(HTTPS_REQUEST_TYPE.API)
    setLoading(true)

    // Set Battery Details
    try {
      const resProfile = await setBatteryEVChargeDetails(obj)
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t('bp260'),
          autoClose: 3000,
        })
        setDeviceData({batteryConfig: true, ...obj})
        setLoading(false)
        setInProgress(false)
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.error('[Error] Charge Battery for EV - Save', error)

      setToggleStatus(toggleStatus === TOGGLE_SWITCH_STATUS.ON ? TOGGLE_SWITCH_STATUS.OFF : TOGGLE_SWITCH_STATUS.ON)
      setRequestType(null)
      setLoading(false)
      setInProgress(false)

      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp261'),
        autoClose: 3000,
      })
    }
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  // Render Toggle
  const renderToggle = () => {
    const tStatus = toggleStatus
    const tLoading = loading
    const disabled = tLoading

    return (
      <div className="cbfev__status__toggle-container">
        {isWeb() && (
          <div className="cbfev__status-text">{toggleStatusInfo({loading: tLoading, status: toggleStatus})}</div>
        )}
        <ToggleSwitch
          key="cbfev-toggle"
          id="cbfev-toggle"
          status={tStatus}
          disabled={disabled}
          handleChange={handleToggleChange}
        />
      </div>
    )
  }

  // Render Status Info
  const renderStatusInfo = () => (
    <>
      <div className="cbfev__status-text">{toggleStatusInfo({loading, status: toggleStatus})}</div>

      <div className="cbfev__info" onClick={onClickInfo} onKeyDown={() => {}} role="button" tabIndex="0">
        <Icon src="info" />
      </div>
    </>
  )

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Charge Battery for EV
  if (!showBatteryForEvCharging()) {
    return false
  }

  return (
    // Use Battery For EV charging
    <section id="bp-use-battery-for-ev" className="bp__card-view">
      <div className="bp__card-view__wrapper">
        <div className="cbfev__title-toggle">
          <div className="bp__card-view--title">
            {cbfevTitle}{' '}
            {isWeb() && (
              <span onClick={onClickInfo} onKeyDown={onClickInfo} role="button" tabIndex={0}>
                <Icon src="info" />
              </span>
            )}
          </div>
          <div className="cbfev__toggle">{renderToggle()}</div>
        </div>

        {!isWeb() && <div className="cbfev__status-info">{renderStatusInfo()}</div>}
        {cbfevDescription && <div className="bp__card-view--desc">{cbfevDescription}</div>}
      </div>

      {isInfoChanged() && (
        <IntractiveButton
          className="cbfev__button ib__btn"
          type={cbfevButtonActionType}
          label={cbfevButtonLabel}
          loading={loading}
          disabled={!isInfoChanged()}
          onClick={onActionButtonClick}
        />
      )}
    </section>
  )
}

ChargeBatteryForEv.propTypes = {}

ChargeBatteryForEv.defaultProps = {}
