import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {navigateToSchedule, navigateToThirdPartyEvseSchedule} from 'lib/common'
import {customScheduleEnabled, isEvseSite} from 'lib/utils/siteDetails'
import {getScheduleTranslation} from 'lib/component/common/molecules/DeviceBehaviour/dbCore'
import {getStormAlertDetails} from 'lib/component/common/organisms/StormGuardDetails/sgCore'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {DEVICE_TYPE, SOURCE_TYPE, STORM_GUARD_STATUS} from 'lib/utils/constants'
import {capitalizeFirstLetter, isSource, textEllipsis} from 'lib/utils/utility'
import {getDevices} from './evScheduleCore'
import Icon from '../../../../utils/icon'
import './styles.scss'

/* *********************** *********************** *********************** *
 * EV Top Up Schedule
 * *********************** *********************** *********************** */

const EvTopUpSchedule = () => {
  const batteryProfile = useSelector(batteryProfileSelector)
  const {details} = batteryProfile
  const devices = getDevices(details)
  const caption = i18next.t('bp216')

  const dispatch = useDispatch()
  // const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const closeThisDialog = () => dispatch(closeDialog())

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const onClickEvScheduleCard = ({id, deviceName}) => {
    // setBpDetails({subPage: [...batteryProfile.subPage, PAGE_NAME.EV_SCHEDULE]})

    if (details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE) {
      showDialog(getStormAlertDetails({details, closeThisDialog}))
      return
    }

    if (isEvseSite()) navigateToSchedule({uuid: id, deviceName, profile: true})
    else navigateToThirdPartyEvseSchedule({uuid: id, deviceName, profile: true})
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  function renderEvCards(evCard) {
    const {id, title, activeSchedule, deviceType} = evCard
    const scheduleName = activeSchedule || 'Disabled'
    const translatedScheduleName = i18next.t(getScheduleTranslation(scheduleName))

    // coexistence of evse and 3rd party chargers
    // 1. (hide 3rd party charger in schedules when HEMS_EV_CUSTOM_SCHEDULE ab gate flag is false)
    // 2. (hide iqevse charger in schedules when source is itk)
    if (
      (deviceType === DEVICE_TYPE.THIRD_PARTY_EVSE && !customScheduleEnabled()) ||
      (deviceType === DEVICE_TYPE.IQ_EVSE && isSource(SOURCE_TYPE.ITK))
    ) {
      return null
    }

    return (
      <div
        className="sp__profile"
        onClick={() => onClickEvScheduleCard({id, deviceName: title})}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0">
        <div className="evs--ev-charger atom-design--t3">
          <div className="com__ad__icon">
            <Icon src="evCharger" />
          </div>

          <div className="com__ad__details">
            <div className="com__ad--title">
              <span>{textEllipsis(title)}</span>
            </div>
            <div className="com__ad--sub-title">{capitalizeFirstLetter(translatedScheduleName)}</div>
          </div>

          <div className="com__ad__action">
            <Icon src="leftArrow" className="arrow-right" />
          </div>
        </div>
      </div>
    )
  }

  function renderEvTopUpSchedule() {
    return (
      <section className="bp__card-view">
        <div className="bp__card-view__wrapper">
          {devices?.map(device => (
            <React.Fragment key={device.uuid}>
              {renderEvCards({
                id: device.uuid,
                title: device.deviceName,
                activeSchedule: device.activeSchedule,
                deviceType: device.deviceType,
              })}
            </React.Fragment>
          ))}
        </div>
      </section>
    )
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // EV Top Up Schedule
    <div className="bp__card--ev-schedule">
      <div className="bp__card--caption">
        <div>{caption}</div>
      </div>

      {renderEvTopUpSchedule()}
    </div>
  )
}

EvTopUpSchedule.propTypes = {}

EvTopUpSchedule.defaultProps = {}

export default EvTopUpSchedule
