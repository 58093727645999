/* eslint-disable fp/no-mutation */
import {createSlice} from '@reduxjs/toolkit'
import {BATTERY_GRID_MODE, OMST_STATUS, PROFILE_TYPE, STORM_GUARD_STATUS} from 'lib/utils/constants'
import {updateSpecificKeyValue} from 'lib/utils'

const initDetails = {
  supportsMqtt: true,
  pollingInterval: 60,
  systemTask: false,

  drEventActive: false,
  drEventMode: 'active',

  isTariffTou: false,
  isBuybackTariffTou: false,
  buybackExportPlan: null,
  batteryGridMode: BATTERY_GRID_MODE.IMPORT_ONLY,

  hideChargeFromGrid: false,
  envoySupportsVls: true,

  prevProfile: PROFILE_TYPE.SELF_CONSUMPTION,
  profile: PROFILE_TYPE.SELF_CONSUMPTION,
  batteryBackupPercentage: 30,
  batteryBackupPercentageMin: 10,
  batteryBackupPercentageMax: 100,
  operationModeSubType: OMST_STATUS.PRIORITIZE_ENERGY,
  previousBatteryBackupPercentage: {
    [PROFILE_TYPE.COST_SAVINGS]: 20,
    [PROFILE_TYPE.SELF_CONSUMPTION]: 30,
    [PROFILE_TYPE.BACKUP_ONLY]: 100,
  },

  stormGuardState: STORM_GUARD_STATUS.DISABLED,
  acceptedStormGuardDisclaimer: true,
  showStormGuardAlert: false,
  stormAlertMessage: null,

  chargeFromGrid: false,
  chargeBeginTime: 120,
  chargeEndTime: 300,
  chargeFromGridScheduleEnabled: false,
  acceptedItcDisclaimer: false,

  veryLowSoc: 20,
  veryLowSocMin: 5,
  veryLowSocMax: 25,

  requestedConfigMqtt: {},
  requestedConfig: {},
  devices: {},
  evseSiteConfig: {},

  cfgControl: {
    show: false,
    locked: false,
    scheduleSupported: false,
    forceScheduleSupported: false,
  },

  dtgControl: {
    show: false,
    enabled: false,
    locked: false,
    scheduleSupported: false,
    startTime: 960,
    endTime: 1140,
  },
}

const initialState = {
  loading: true,
  apiError: false,
  invalidAccess: false,
  noActiveFeatureForBattery: false,
  subPage: [],
  details: initDetails,
}

export const batteryProfileSlice = createSlice({
  name: 'batteryProfile',
  initialState,
  reducers: {
    getBpDetails: state => state,
    setBpValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },

    setBpDetailsValue: (state, action) => {
      const {payload} = action
      const {details} = state

      updateSpecificKeyValue(payload, details)
    },

    setBpDevicesValues: (state, action) => {
      const {payload} = action
      const {details} = state

      if (!payload.batteryConfig) {
        const devices = JSON.parse(JSON.stringify(details.devices))

        if (devices?.iqEvse?.length) {
          const updatedDevices = devices.iqEvse.map(device => {
            if (device.uuid === payload.uuid) {
              return updateSpecificKeyValue(payload, device)
            }
            return device
          })
          details.devices.iqEvse = updatedDevices
        }
        if (devices?.thirdPartyEvse?.length) {
          const updatedDevices = devices.thirdPartyEvse.map(device => {
            if (device.uuid === payload.uuid) {
              return updateSpecificKeyValue(payload, device)
            }
            return device
          })
          details.devices.thirdPartyEvse = updatedDevices
        }
      } else {
        const {useBatteryFrSelfConsumption} = payload
        details.evseSiteConfig.iqEvse = {useBatteryFrSelfConsumption}
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {getBpDetails, setBpValue, setBpDetailsValue, setBpDevicesValues} = batteryProfileSlice.actions
export const batteryProfileSelector = state => state.system.batteryProfile
export default batteryProfileSlice.reducer
