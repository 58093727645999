/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import _ from 'lodash'
import {ACTION_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import {isEvseSiteForBattery, showBatteryForEvCharging} from 'lib/utils/siteDetails'

//  Use Battery for EV Charging :: Card Details
export function getCardDetails() {
  return {
    buttonLabel: i18next.t('bp210'),
    buttonActionType: ACTION_TYPE.SAVE,
  }
}

// Use Battery for EV :: Get Charge for EV status
export function getChargeForEV(details) {
  let chargeForEv
  if (!_.isEmpty(details.evseSiteConfig)) {
    chargeForEv = details?.evseSiteConfig?.iqEvse?.useBatteryFrSelfConsumption
  } else {
    chargeForEv = details?.devices?.iqEvse?.useBatteryFrSelfConsumption
  }
  return chargeForEv
}

// Charge Battery for EV :: Get Toggle Status
export function getCbfevToggleStatus(details, loading) {
  const useBatteryFrSelfConsumption = getChargeForEV(details)
  if (loading) {
    return TOGGLE_SWITCH_STATUS.LOADING
  }

  if (useBatteryFrSelfConsumption) {
    return TOGGLE_SWITCH_STATUS.ON
  }

  return TOGGLE_SWITCH_STATUS.OFF
}

// Charge Battery from Grid :: Get Description
export function getDescription(status) {
  let text = ''
  if (showBatteryForEvCharging()) {
    if (status === TOGGLE_SWITCH_STATUS.ON) {
      text = i18next.t('bp262')
    } else if (status === TOGGLE_SWITCH_STATUS.OFF) {
      text = i18next.t('bp263')
    }
  }
  return text
}

// Use Battery for EV Charging :: Get Info Description
export function getInfoDescription() {
  const descEnabled = i18next.t('bp264')
  const descDisabled = i18next.t('bp265')
  let descNote

  return (
    <div className="cbfev__popup__wrapper">
      <div className="e_dialog__content">
        <span className="bp__status-text--enabled">{i18next.t('bp7')}</span>: {descEnabled}
      </div>
      <div className="e_dialog__content">
        <span className="bp__status-text--disabled">{i18next.t('bp8')}</span>: {descDisabled}
      </div>
      {Boolean(descNote) && (
        <div className="e_dialog__content" style={{color: '#7B8793'}}>
          <span className="bp__status-text--disabled" style={{color: '#7B8793'}}>
            {i18next.t('bp98')}
          </span>
          : {descNote}
        </div>
      )}
    </div>
  )
}

