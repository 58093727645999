import React from 'react'

const PrevHeader = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4993 0.910792L10.4083 0.814518C10.3427 0.759565 10.268 0.713302 10.1862 0.677896C10.0671 0.626351 9.93662 0.599498 9.804 0.599982C9.67138 0.600466 9.54119 0.628269 9.42254 0.680654C9.30395 0.733016 9.20065 0.808236 9.11862 0.89959L9.09634 0.924412L1.41714 7.76988L1.41126 7.77498C1.36377 7.81623 1.32763 7.86492 1.30354 7.91693C1.2795 7.96886 1.26758 8.02382 1.26758 8.07857C1.26758 8.13332 1.2795 8.18829 1.30354 8.24021C1.32762 8.29223 1.3638 8.34088 1.41129 8.38212L9.03073 15.1693C9.25205 15.3181 9.51923 15.4 9.79587 15.4C10.0608 15.4 10.3171 15.3249 10.5328 15.1878C10.6649 15.0257 10.7342 14.8295 10.7342 14.6308C10.7342 14.421 10.657 14.2141 10.5101 14.0471L3.77036 8.0771L9.41859 3.0453C9.93562 2.58521 10.3527 2.12487 10.552 1.71607C10.6484 1.51856 10.6766 1.36751 10.6686 1.25493C10.6616 1.15483 10.6236 1.04242 10.4993 0.910792Z"
      fill="white"
    />
  </svg>
)

PrevHeader.propTypes = {}

PrevHeader.defaultProps = {}

export default PrevHeader
